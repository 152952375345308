// import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button/Button';
import Image from '../Image/Image';
import styles from './CtaWithImage.module.scss';

const CtaWithImage = ({
    image = {},
    title = '',
    text = '',
    link = {},
    isReversed = false,
}) => {
    const classes = classNames(styles['CtaWithImage'], {
        [styles['CtaWithImage--Reverse']]: isReversed,
    });

    return (
        <div className={classes}>
            <div className={styles['CtaWithImage__Container']}>
                <figure className={styles['CtaWithImage__Image']}>
                    <Image {...{ image }} />
                </figure>
                <div className={styles['CtaWithImage__Content']}>
                    <h2 className={styles['CtaWithImage__Title']}>{title}</h2>
                    <p className={styles['CtaWithImage__Text']}>{text}</p>
                    <Button modifiers={['tertiary']} {...{ link }} />
                </div>
            </div>
        </div>
    );
};

CtaWithImage.propTypes = {};

export default CtaWithImage;
